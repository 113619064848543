module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#fdfdc4',

  text: '#333333',

  primary: '#ff9292',
  primaryLight: '#ffc5c5',
  primaryDark: '#ffabab',

  secondary: '#80ffd0',
  secondaryLight: '#b3ffe3',
  secondaryDark: '#ff9292',
};
